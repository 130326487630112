* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.nav-back {
  background-color: #F55A5A;
  height: 55px;
  width: 550px;
  margin: 0px;
  border-radius: 1px;
}

.nav-heading {
  position: absolute;
  height: 16px;
  width: 109px;
  margin-left: 244px;
  color: #FFFFFF;
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14.46px;

}

.nav-globe {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 215px;
  top: 12px;
}


.Card-back {
  display: flex;
  flex-wrap: wrap;
}

.city-pic, .location-icon {
  float: left;
}

.city-pic {
  width: 125px;
  height: 168px;
  margin: 50px 0px 0px 40px;
  border-radius: 5px;
}


.location-icon {
  height: 9.5px;
  width: 7px;
  margin: 65px 0px 0px 18px;
}

.city-name {
  width: 80px;
  height: 10px;
  margin: 63px 0px 0px 8px;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 10.24px;
  letter-spacing: 0.17em;
  text-align: start;
}

.google-map {
  height: 15px;
  width: 120px;
  font-size: 10.24px;
  color: #918E9B;
  font-weight: 400;
  margin: 60px 0px 0px 8px;
}

.location {
  font-size: 25px;
  font-family: 'Inter';
  font-weight: 700;
  color: #2B283A;
  margin: 10px 0px 0px 18px;
  text-align: start;
  height: 28px;
  width: 326px;
}

.Main-Card {
  display: flex;
  flex-direction: column;
}

.date {
  font-size: 15.24px;
  font-family: 'Inter';
  font-weight: 700;
  color: #2B283A;
  margin: 20px 0px 0px 10px;
  height: 15px;
  width: 180px;
}

.discription {
width: 280px;
height: 45px;
margin-left: 19px;
margin-top: 20px;
text-align: start;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10.24px;
line-height: 150%;
}



